import React, { useEffect, useRef } from 'react';
import { MindARThree } from 'mind-ar/dist/mindar-image-three.prod.js';
import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';

const MindARThreeViewer = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const mindarThree = new MindARThree({
      container: containerRef.current,
      imageTargetSrc: "/targets.mind"
    });
    const { renderer, scene, camera } = mindarThree;
    const anchor = mindarThree.addAnchor(0);
    // const geometry = new THREE.PlaneGeometry(1, 0.55);
    // const material = new THREE.MeshBasicMaterial({ color: 0x00ffff, transparent: true, opacity: 0.5 });
    // const plane = new THREE.Mesh(geometry, material);
    // anchor.group.add(plane);
    camera.position.set(0, 0, 20000);
    loadModel(anchor.group)
    mindarThree.start();
    renderer.setAnimationLoop(() => {
      renderer.render(scene, camera);
    });

    return () => {
      renderer.setAnimationLoop(null);
      mindarThree.stop();
    }
  }, []);


  const loadModel = (scene) => {
    new GLTFLoader().load('/gltf/scene.gltf', (gltf) => {
      const model = gltf.scene;
      model.scale.set(2, 2, 2)
      scene.add(model);
      model.traverse((obj) => {
        console.log(obj.name);
        if (obj.name === 'Text_0') {
          console.log(obj.name);
          console.log(obj);
          obj.material = new THREE.MeshBasicMaterial({
            color: '#ff0000',
            // metalness: 1,
            // roughness: 0.5,
            // clearcoat: 1.0,
            // clearcoatRoughness: 0.03
          })
          // const loader = new FontLoader();
          // loader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', function (font) {
          //   obj.geometry = new TextGeometry('Hello three.js!', {
          //     font: font,
          //     size: 80,
          //     height: 5,
          //     curveSegments: 12,
          //     bevelEnabled: true,
          //     bevelThickness: 10,
          //     bevelSize: 8,
          //     bevelOffset: 0,
          //     bevelSegments: 5
          //   });
          // });
        }
      })
    })
  }

  return (
    <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
    </div>
  )
}

export default MindARThreeViewer;